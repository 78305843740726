<template>
  <q-card class="q-pa-md" flat bordered>
    <!-- Name -->
    <div class="text-h4 q-mb-md">
      <span>{{ data.firstName + " " }}</span>
      <span v-if="data.middleName">{{ data.middleName + " " }}</span>
      <span>{{ data.lastName + " " }}</span>
    </div>

    <div class="row">
      <div class="col-6">
        <!-- Contacts -->
        <div class="row">
          <span class="col-3">{{ getRes('Form.Field.EmailAddress') }}</span>
          <span class="q-ml-sm q-mr-sm">:</span>
          <span class="col" v-if="data['email']">
            {{ data["email"] }}
          </span>
        </div>

        <div
          class="row"
          v-for="({ label, numKey, areaCodeKey }, index) in numKeyValueList"
          :key="index"
        >
          <span class="col-3">{{ label }}</span>
          <span class="q-ml-sm q-mr-sm">:</span>
          <div class="col">
            <span v-if="data[areaCodeKey]">
              {{ `(${data[areaCodeKey]}) ` }}
            </span>
            <span v-if="data[numKey]">
              {{ data[numKey] }}
            </span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <!-- Purposes -->
        <div
          class="row"
          v-for="({ label, key }, index) in purposeKeyValueList"
          :key="index"
        >
          <span class="col-4">{{ label }}</span>
          <span class="q-ml-sm q-mr-sm">:</span>
          <span class="col" v-if="data[key]">
            {{ data[key] }}
          </span>
        </div>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
  name: "RegisteredSupplierContactCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    numKeyValueList() {
      return [
        {
          label: this.getRes("Form.Field.OfficeTel"),
          numKey: "phoneNoOffice",
          areaCodeKey: "telAreaCodeOffice",
        },
        {
          label: this.getRes("Form.Field.MobileTel"),
          numKey: "phoneNoMobile",
          areaCodeKey: "telAreaCodeMobile",
        },
      ];
    },

    purposeKeyValueList() {
      return [
        { label: this.getRes("Form.Field.POPurpose"), key: "poPurpose" },
        { label: this.getRes("Form.Field.RFQPurpose"), key: "rfqPurpose" },
        { label: this.getRes("Form.Field.PaymentPurpose"), key: "paymentPurpose" },
        { label: this.getRes("Form.Field.ManagementPurpose"), key: "managementPurpose" },
        { label: this.getRes("Form.Field.FactoryPurpose"), key: "factoryPurpose" },
        { label: this.getRes("Form.Field.OthersPurpose"), key: "otherPurpose" },
      ];
    },
  },
};
</script>

<style scoped></style>
