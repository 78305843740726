import { render, staticRenderFns } from "./RegisteredSuppliers.vue?vue&type=template&id=2bde4cfb&"
import script from "./RegisteredSuppliers.vue?vue&type=script&lang=js&"
export * from "./RegisteredSuppliers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QBadge,QInput,QBtn,QCard,QItemSection,QSeparator,QTable,QTd,QChip});
