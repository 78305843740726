<template>
  <div>
    <div v-if="loading">
      <q-skeleton height="50px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
    </div>
    <div class="q-gutter-sm q-pa-sm" v-else>
      <div class="row items-center no-wrap q-mb-md">
        <div class="row items-center q-gutter-sm no-wrap text-h4">
          <span>{{ getRes("CustMenu.Title.RegisteredSuppliers") }}</span>
          <!--          <q-badge color="primary" align="middle">-->
          <!--            {{ mainCategories.length }}-->
          <!--          </q-badge>-->
        </div>

        <q-input
          outlined
          dense
          :label="getRes('CustMenu.Title.SearchSuppliers')"
          class="q-ml-md"
          style="width: 300px; margin-left: auto"
          v-model.lazy="queryString"
          @input="handleFilter"
        >
          <template v-slot:append>
            <q-btn flat round dense icon="search" no-caps />
          </template>
        </q-input>
      </div>

      <q-card
        bordered
        flat
        v-for="({ description, suppliers }, index) in mainCategories"
        :key="index"
        :style="`display: ${!suppliers.length && !showAll ? 'none' : 'block'}`"
      >
        <ProExpansionItem
          :expandable="!!suppliers.length"
          group="suppliersGroup"
        >
          <template #header>
            <q-item-section>
              <div class="row no-wrap items-center q-col-gutter-md">
                <span class="text-h4">
                  {{ `${description} (${suppliers.length})` }}
                </span>
              </div>
            </q-item-section>
          </template>
          <q-separator />
          <q-item-section>
            <q-table
              flat
              :columns="columns"
              :data="suppliers"
              hide-header
              style="background-color: transparent"
              wrap-cells
              :rows-per-page-options="[5]"
            >
              <template
                v-slot:body-cell-header="{ row: { companyName, supplierId } }"
              >
                <q-td style="width: 300px">
                  <div class="text-h5">
                    <u>{{ companyName }}</u>
                  </div>
                  <div style="color: gray">{{ supplierId }}</div>
                </q-td>
              </template>
              <!--              <template-->
              <!--                v-slot:body-cell-others="{ row: { code1Desc, code2Desc } }"-->
              <!--              >-->
              <!--                <q-td>-->
              <!--                  <div-->
              <!--                    class="row items-center"-->
              <!--                    v-for="{ label, desc } in [-->
              <!--                      {-->
              <!--                        label: 'CustMenu.Field.ProductCategoryLevel1',-->
              <!--                        desc: code1Desc,-->
              <!--                      },-->
              <!--                      {-->
              <!--                        label: 'CustMenu.Field.ProductCategoryLevel2',-->
              <!--                        desc: code2Desc,-->
              <!--                      },-->
              <!--                    ]"-->
              <!--                    :key="desc"-->
              <!--                  >-->
              <!--                    <span>{{ label.padEnd(44, "\xa0") }}</span>-->
              <!--                    <span>:</span>-->
              <!--                    <q-chip dense outline square color="primary">-->
              <!--                      {{ desc }}-->
              <!--                    </q-chip>-->
              <!--                  </div>-->
              <!--                </q-td>-->
              <!--              </template>-->
              <template v-slot:body-cell-view="{ row: { supplierId } }">
                <q-td auto-width>
                  <q-btn
                    no-wrap
                    no-caps
                    caps
                    flat
                    icon="PROSmart-Docs"
                    :label="getRes('System.Button.View')"
                    @click="
                      displayDialog = true;
                      selectedSupplierId = supplierId;
                    "
                  />
                </q-td>
              </template>
            </q-table>
          </q-item-section>
        </ProExpansionItem>
      </q-card>

      <RegisteredSupplierDialog
        v-model="displayDialog"
        :supplierId="selectedSupplierId"
      />
    </div>
  </div>
</template>

<script>
import ProExpansionItem from "../../../components/PROSmart/QuasarWrapperComponent/ProExpansionItem";
import RegisteredSupplierDialog from "../../../components/PROSmart/RegisteredSupplier/RegisteredSupplierDialog";

export default {
  name: "RegisteredSuppliers",
  components: { RegisteredSupplierDialog, ProExpansionItem },
  data() {
    return {
      loading: true,
      displayDialog: false,
      showAll: true,
      suppliers: [],
      filteredSuppliers: [],
      commodityCodeList: [],
      queryString: "",
      selectedSupplierId: "",
    };
  },

  methods: {
    handleFilter() {
      const str = this.queryString.toLowerCase();
      let isEmpty;
      this.showAll = isEmpty = str === "";
      if (isEmpty) {
        this.filteredSuppliers = [...this.suppliers];
      } else {
        this.filteredSuppliers = this.suppliers.filter((item) =>
          Object.keys(item).some((key) =>
            String(item[key]).toLowerCase().includes(str)
          )
        );
      }
    },
  },

  async beforeMount() {
    const { hkjc, procurerUi } = this.$proSmart;
    this.suppliers = await hkjc.getYellowPageSupplierList(this);
    this.filteredSuppliers = [...this.suppliers];
    this.commodityCodeList = await procurerUi.getProcurerCommodity(this);
    this.loading = false;
  },

  computed: {
    columns() {
      return [
        { name: "header", align: "left" },
        { name: "others", align: "left" },
        { name: "view", align: "right" },
      ];
    },

    mainCategories() {
      return this.commodityCodeList
        .filter(({ code }) => /^\d+-\d+$/.test(code))
        .sort(({ description: d1 }, { description: d2 }) => {
          if (d1 === d2) {
            return 0;
          }
          return d1 < d2 ? -1 : 1;
        })
        .map((mainCategory) => ({
          ...mainCategory,
          suppliers: this.filteredSuppliers.filter(
            ({ code1, code2 }) =>
              code1.substring(0, 2).replace(/^0+/, "") === mainCategory.code1 &&
              code2.substring(2, 4).replace(/^0+/, "") === mainCategory.code2
          ),
        }));
    },
  },
};
</script>
