<template>
  <div>
    <pro-deck
      :title="title"
      :cards="list"
      :statuses="statuses"
      v-slot="{ card, status }"
      filter-by-status
      :hideCountBadge="true"
    >
      <pro-deck-card
        v-bind="card"
        :status="status ? getRes(status.label) : ''"
        :statusColor="status ? status.color : ''"
        expandable
      >
        <template>
          <q-markup-table dense flat separator="none">
            <thead></thead>
            <tbody>
              <tr class="q-tr--no-hover">
                <td>{{ getRes("CustMenu.Field.MainCategory") }}:</td>
                <td>{{ card.mainCategory.join(", ") }}</td>
              </tr>
              <tr class="q-tr--no-hover">
                <td>{{ getRes("CustMenu.Field.SubCategories") }}:</td>
                <td>{{ card.subCategory.join(", ") }}</td>
              </tr>
              <tr class="q-tr--no-hover">
                <td>{{ getRes("CustMenu.Field.For") }}:</td>
                <td>{{ card.tenderName }}</td>
              </tr>
            </tbody>
          </q-markup-table>
        </template>

        <template v-slot:action>
          <q-btn-group outline>
            <q-btn
              no-caps
              icon="PROSmart-Docs"
              :label="getRes('System.Button.View')"
              @click.stop="showRecord(card.processId, card.tenderId)"
            />
          </q-btn-group>
        </template>
      </pro-deck-card>
    </pro-deck>
    <audit-trail-record-dialog ref="audit"></audit-trail-record-dialog>
  </div>
</template>

<script>
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import AuditTrailRecordDialog from "@/components/PROSmart/AuditTrailRecordDialog";

export default {
  name: "LightWeightRegistrationsCardList",
  components: { AuditTrailRecordDialog, ProDeckCard, ProDeck },
  props: {
    title: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      },
    },
  },
  data() {
    return {
      statuses: [
        {
          key: "AutoApproved",
          label: this.getRes("CustMenu.Col.AutoApproved"),
          color: "purple",
        },
        {
          key: "Approved",
          label: this.getRes("CustMenu.Col.Approved"),
          color: "green",
        },
        {
          key: "Submitted",
          label: this.getRes("CustMenu.Col.Submitted"),
          color: "orange",
        },
        {
          key: "Rejected",
          label: this.getRes("CustMenu.Col.Rejected"),
          color: "red",
        },
      ],
    };
  },
  methods: {
    showRecord(processId, tenderId) {
      this.$refs.audit.showInfoViewFormByName(
        processId,
        tenderId,
        true,
        "Supplier_Tender_NewLightReg_Approval_V"
      );
    },
  },
};
</script>
