var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"q-pa-md",attrs:{"flat":"","bordered":""}},[_c('div',{staticClass:"text-h4 q-mb-md"},[_c('span',[_vm._v(_vm._s(_vm.data.firstName + " "))]),(_vm.data.middleName)?_c('span',[_vm._v(_vm._s(_vm.data.middleName + " "))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.data.lastName + " "))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-3"},[_vm._v(_vm._s(_vm.getRes('Form.Field.EmailAddress')))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),(_vm.data['email'])?_c('span',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.data["email"])+" ")]):_vm._e()]),_vm._l((_vm.numKeyValueList),function(ref,index){
var label = ref.label;
var numKey = ref.numKey;
var areaCodeKey = ref.areaCodeKey;
return _c('div',{key:index,staticClass:"row"},[_c('span',{staticClass:"col-3"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),_c('div',{staticClass:"col"},[(_vm.data[areaCodeKey])?_c('span',[_vm._v(" "+_vm._s(("(" + (_vm.data[areaCodeKey]) + ") "))+" ")]):_vm._e(),(_vm.data[numKey])?_c('span',[_vm._v(" "+_vm._s(_vm.data[numKey])+" ")]):_vm._e()])])})],2),_c('div',{staticClass:"col-6"},_vm._l((_vm.purposeKeyValueList),function(ref,index){
var label = ref.label;
var key = ref.key;
return _c('div',{key:index,staticClass:"row"},[_c('span',{staticClass:"col-4"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),(_vm.data[key])?_c('span',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.data[key])+" ")]):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }