<template>
  <div>
    <div v-if="list.length === 0">
      <q-skeleton height="50px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
    </div>
    <div class="q-ma-md" v-else>
      <light-weight-registrations-card-list
        :title="getRes('CustMenu.Title.LightWeightRegistration')"
        :list="list"
      />
    </div>
  </div>
</template>
 
<script>
import LightWeightRegistrationsCardList from "@/components/PROSmart/LightWeightRegistrationsCardList";

export default {
  name: "LightWeightRegistrations",
  components: { LightWeightRegistrationsCardList },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$proSmart.documentUi
      .getAllLightWeightRegistrationList(this)
      .then((v) => {
        this.list = v.lightWeightRegistrationList.reduce(
          (tenderers, { name: title, status, commodityCode, ...rest }) => {
            let mainCategory = [];
            let subCategory = [];
            if (commodityCode) {
              let split = commodityCode.split(",");
              split.forEach((b) => {
                let lv1 = b.split("-")[0];
                mainCategory.push(v.commodityCode[lv1]);
                subCategory.push(v.commodityCode[b]);
              });
            }

            return [
              ...tenderers,
              {
                title,
                status,
                commodityCode,
                mainCategory,
                subCategory,
                ...rest,
              },
            ];
          },
          []
        );
      });
  },
};
</script>
