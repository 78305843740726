<template>
  <div class="q-pa-md">
    <div class="q-gutter-y-md" style="height: 100%">
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
          no-caps
        >
          <q-tab
            name="RegisteredSuppliers"
            :label="getRes('CustMenu.Title.RegisteredSuppliers')"
          />
          <q-tab
            name="PotentialSuppliers"
            :label="getRes('CustMenu.Title.PotentialSuppliers')"
          />
          <q-tab
            name="LightWeightRegistrations"
            :label="getRes('CustMenu.Title.LightWeightRegistration')"
          />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="RegisteredSuppliers">
            <registered-suppliers />
          </q-tab-panel>
          <q-tab-panel name="PotentialSuppliers">
            <potential-suppliers />
          </q-tab-panel>
          <q-tab-panel name="LightWeightRegistrations">
            <light-weight-registrations />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </div>
</template>

<script>
import PotentialSuppliers from "./PotentialSuppliers";
import LightWeightRegistrations from "./LightWeightRegistrations";
import RegisteredSuppliers from "./RegisteredSuppliers";

export default {
  name: "SourcingCategories",
  components: {
    RegisteredSuppliers,
    LightWeightRegistrations,
    PotentialSuppliers,
  },
  data() {
    return {
      tab: "RegisteredSuppliers",
    };
  },
};
</script>
