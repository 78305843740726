var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_c('q-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"50px"}}),_c('q-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"120px"}}),_c('q-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"120px"}}),_c('q-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"120px"}}),_c('q-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"120px"}}),_c('q-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"120px"}})],1):_c('div',{staticClass:"q-gutter-sm q-pa-sm"},[_c('div',{staticClass:"row items-center no-wrap q-mb-md"},[_c('div',{staticClass:"row items-center q-gutter-sm no-wrap text-h4"},[_c('span',[_vm._v(_vm._s(_vm.getRes("CustMenu.Title.RegisteredSuppliers")))])]),_c('q-input',{staticClass:"q-ml-md",staticStyle:{"width":"300px","margin-left":"auto"},attrs:{"outlined":"","dense":"","label":_vm.getRes('CustMenu.Title.SearchSuppliers')},on:{"input":_vm.handleFilter},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-btn',{attrs:{"flat":"","round":"","dense":"","icon":"search","no-caps":""}})]},proxy:true}]),model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1),_vm._l((_vm.mainCategories),function(ref,index){
var description = ref.description;
var suppliers = ref.suppliers;
return _c('q-card',{key:index,style:(("display: " + (!suppliers.length && !_vm.showAll ? 'none' : 'block'))),attrs:{"bordered":"","flat":""}},[_c('ProExpansionItem',{attrs:{"expandable":!!suppliers.length,"group":"suppliersGroup"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('q-item-section',[_c('div',{staticClass:"row no-wrap items-center q-col-gutter-md"},[_c('span',{staticClass:"text-h4"},[_vm._v(" "+_vm._s((description + " (" + (suppliers.length) + ")"))+" ")])])])]},proxy:true}],null,true)},[_c('q-separator'),_c('q-item-section',[_c('q-table',{staticStyle:{"background-color":"transparent"},attrs:{"flat":"","columns":_vm.columns,"data":suppliers,"hide-header":"","wrap-cells":"","rows-per-page-options":[5]},scopedSlots:_vm._u([{key:"body-cell-header",fn:function(ref){
var ref_row = ref.row;
var companyName = ref_row.companyName;
var supplierId = ref_row.supplierId;
return [_c('q-td',{staticStyle:{"width":"300px"}},[_c('div',{staticClass:"text-h5"},[_c('u',[_vm._v(_vm._s(companyName))])]),_c('div',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(supplierId))])])]}},{key:"body-cell-view",fn:function(ref){
var supplierId = ref.row.supplierId;
return [_c('q-td',{attrs:{"auto-width":""}},[_c('q-btn',{attrs:{"no-wrap":"","no-caps":"","caps":"","flat":"","icon":"PROSmart-Docs","label":_vm.getRes('System.Button.View')},on:{"click":function($event){_vm.displayDialog = true;
                    _vm.selectedSupplierId = supplierId;}}})],1)]}}],null,true)})],1)],1)],1)}),_c('RegisteredSupplierDialog',{attrs:{"supplierId":_vm.selectedSupplierId},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }