<template>
  <div>
    <div v-if="commodityList.length === 0">
      <q-skeleton height="50px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
      <q-skeleton height="120px" style="margin-top: 10px" />
    </div>
    <div class="q-ma-md" v-else>
      <pro-deck
        :title="getRes('CustMenu.Title.PotentialSuppliers')"
        :cards="commodityList"
        v-slot="{ card }"
        hideCountBadge
      >
        <pro-deck-card v-bind="card" expandable defaultClosed>
          <template>
            <light-weight-registrations-card-list
              v-if="list[card.name]"
              :list="list[card.name]"
            />
            <div v-else class="text-center q-pa-md">
              {{ $t("Form.Message.Error.NoRecordFound") }}
            </div>
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
  </div>
</template>

<script>
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import LightWeightRegistrationsCardList from "@/components/PROSmart/LightWeightRegistrationsCardList";

export default {
  name: "PotentialSuppliers",
  components: { LightWeightRegistrationsCardList, ProDeckCard, ProDeck },
  data() {
    return {
      commodityCode: null,
      list: [],
      commodityList: [],
    };
  },
  methods: {
    updateCountList() {
      for (let key in this.commodityList) {
        let element = this.commodityList[key];
        element.title = `${element.title} (${
          this.list[element.title] == null ? 0 : this.list[element.title].length
        })`;
      }
    },
  },
  created() {
    this.$proSmart.documentUi
      .getAllLightWeightRegistrationList(this)
      .then((v) => {
        this.commodityCode = v.commodityCode;

        for (let commodityCodeKey in this.commodityCode) {
          if (commodityCodeKey.match(/^[0-9]*-[0-9]*$/)) {
            this.commodityList.push({
              title: this.commodityCode[commodityCodeKey],
              name: this.commodityCode[commodityCodeKey],
              key: commodityCodeKey,
            });
          }
        }

        let supplierCategoryList = [];

        let supplierList = v.lightWeightRegistrationList.reduce(
          (tenderers, { name: title, status, commodityCode, ...rest }) => {
            let mainCategory = [];
            let subCategory = [];
            if (commodityCode) {
              let split = commodityCode.split(",");
              split.forEach((b) => {
                let lv1 = b.split("-")[0];
                mainCategory.push(v.commodityCode[lv1]);
                subCategory.push(v.commodityCode[b]);
              });
            }

            return [
              ...tenderers,
              {
                title,
                status,
                commodityCode,
                mainCategory,
                subCategory,
                ...rest,
              },
            ];
          },
          []
        );

        supplierList.forEach((b) => {
          let splitCommodity = b.commodityCode.split("-");
          let commodity = this.commodityCode[
            splitCommodity[0] + "-" + splitCommodity[1]
          ];

          if (!supplierCategoryList[commodity]) {
            supplierCategoryList[commodity] = [];
          }

          supplierCategoryList[commodity].push(b);
        });
        this.list = supplierCategoryList;

        this.updateCountList();
      });
  },
};
</script>
