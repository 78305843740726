<template>
  <q-dialog v-model="localValue" @close="$emit('input', false)">
    <q-card style="width: 100%; max-width: 1000px" v-if="loading === false">
      <q-card-section class="row no-wrap items-center">
        <span class="text-h4">{{ getRes('Form.Field.SupplierDetails') }}</span>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-separator />

      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="details" icon="article" :label="getRes('Form.Field.Detail')" />
        <q-tab name="contacts" icon="contact_page" :label="getRes('Form.Section.Contact')">
          <q-badge floating>{{ data.supplierDetails.length }}</q-badge>
        </q-tab>
      </q-tabs>
      <q-separator />
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="details" style="max-height: 60vh; height: 60vh">
          <div class="q-gutter-md">
            <div>
              <div class="text-h4">{{ data.companyName }}</div>
              <div style="color: gray">{{ data.supplierId }}</div>
            </div>

            <div>
              <div
                v-for="({ label, key }, index) in [
                  { label: getRes('Form.Field.BusinessCertificationNo'), key: 'brCertNo' },
                  { label: getRes('Form.Field.BusinessRegistrationNo'), key: 'brRegNo' },
                ]"
                :key="index"
                class="row"
              >
                <span class="col-4">{{ label }}</span>
                <span class="q-ml-sm q-mr-sm">:</span>
                <span class="col" v-if="data[key]">{{ data[key] }}</span>
              </div>
              <div class="row">
                <span class="col-4">{{ getRes('Form.Field.BRExpiryDat') }}</span>
                <span class="q-ml-sm q-mr-sm">:</span>
                <span class="col" v-if="data['brExpDate']">
                  {{ getFormattedDate(data["brExpDate"]) }}
                </span>
              </div>
            </div>

            <div>
              <div
                v-for="({ label, key }, index) in [
                  { label: getRes('Form.Field.AdmissionRegion'), key: 'region' },
                  { label: getRes('Form.Field.AdmissionRegion'), key: 'currency' },
                  {
                    label: getRes('Form.Field.GoodsServicesSupplied'),
                    key: 'goodsServicesSupplied',
                  },
                  {
                    label: getRes('Form.Field.SupplierClassification'),
                    key: 'classification',
                  },
                ]"
                :key="index"
                class="row"
              >
                <span class="col-4">{{ label }}</span>
                <span class="q-ml-sm q-mr-sm">:</span>
                <span class="col" v-if="data[key]">{{ data[key] }}</span>
              </div>
            </div>

            <div>
              <div
                class="row items-center"
                v-for="{ label, desc } in labelChipMap"
                :key="desc"
              >
                <span class="col-4">{{ label }}</span>
                <span class="q-ml-sm q-mr-sm">:</span>
                <q-chip dense outline square color="primary">{{ desc }}</q-chip>
              </div>
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel
          class="q-pa-md"
          name="contacts"
          style="max-height: 60vh; height: 60vh"
        >
          <div class="q-pb-md q-gutter-md">
            <RegisteredSupplierContactCard
              v-for="(supplierDetail, index) in data.supplierDetails"
              :data="supplierDetail"
              :key="index"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-dialog>
</template>

<script>
import RegisteredSupplierContactCard from "./RegisteredSupplierContactCard";
const defaultTab = "details";

export default {
  name: "RegisteredSupplierDialog",
  components: { RegisteredSupplierContactCard },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    supplierId: {
      type: String,
      required: true,
    },
  },

  data: () => {
    return {
      loading: true,
      data: {},
      tab: defaultTab,
    };
  },

  watch: {
    async supplierId(id) {
      this.loading = true;
      this.data = await this.$proSmart.hkjc.getYellowPageSupplier(this, id);
      this.loading = false;
      this.tab = defaultTab;
    },
  },

  methods: {
    getFormattedDate(dateString) {
      return this.$proSmart.common.getFormattedDate(
        new Date(dateString + "+0800")
      );
    },
  },

  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },

    labelChipMap() {
      const { code1Desc, code2Desc } = this.data;
      return [
        {
          label: this.getRes("CustMenu.Field.ProductCategoryLevel1"),
          desc: code1Desc,
        },
        {
          label: this.getRes("CustMenu.Field.ProductCategoryLevel2"),
          desc: code2Desc,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
