var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-dialog',{on:{"close":function($event){return _vm.$emit('input', false)}},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[(_vm.loading === false)?_c('q-card',{staticStyle:{"width":"100%","max-width":"1000px"}},[_c('q-card-section',{staticClass:"row no-wrap items-center"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.getRes('Form.Field.SupplierDetails')))]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":""}})],1),_c('q-separator'),_c('q-tabs',{staticClass:"text-grey",attrs:{"dense":"","active-color":"primary","indicator-color":"primary","align":"justify","narrow-indicator":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab',{attrs:{"name":"details","icon":"article","label":_vm.getRes('Form.Field.Detail')}}),_c('q-tab',{attrs:{"name":"contacts","icon":"contact_page","label":_vm.getRes('Form.Section.Contact')}},[_c('q-badge',{attrs:{"floating":""}},[_vm._v(_vm._s(_vm.data.supplierDetails.length))])],1)],1),_c('q-separator'),_c('q-tab-panels',{attrs:{"animated":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab-panel',{staticStyle:{"max-height":"60vh","height":"60vh"},attrs:{"name":"details"}},[_c('div',{staticClass:"q-gutter-md"},[_c('div',[_c('div',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.data.companyName))]),_c('div',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.data.supplierId))])]),_c('div',[_vm._l(([
                { label: _vm.getRes('Form.Field.BusinessCertificationNo'), key: 'brCertNo' },
                { label: _vm.getRes('Form.Field.BusinessRegistrationNo'), key: 'brRegNo' } ]),function(ref,index){
              var label = ref.label;
              var key = ref.key;
return _c('div',{key:index,staticClass:"row"},[_c('span',{staticClass:"col-4"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),(_vm.data[key])?_c('span',{staticClass:"col"},[_vm._v(_vm._s(_vm.data[key]))]):_vm._e()])}),_c('div',{staticClass:"row"},[_c('span',{staticClass:"col-4"},[_vm._v(_vm._s(_vm.getRes('Form.Field.BRExpiryDat')))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),(_vm.data['brExpDate'])?_c('span',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(_vm.data["brExpDate"]))+" ")]):_vm._e()])],2),_c('div',_vm._l(([
                { label: _vm.getRes('Form.Field.AdmissionRegion'), key: 'region' },
                { label: _vm.getRes('Form.Field.AdmissionRegion'), key: 'currency' },
                {
                  label: _vm.getRes('Form.Field.GoodsServicesSupplied'),
                  key: 'goodsServicesSupplied',
                },
                {
                  label: _vm.getRes('Form.Field.SupplierClassification'),
                  key: 'classification',
                } ]),function(ref,index){
              var label = ref.label;
              var key = ref.key;
return _c('div',{key:index,staticClass:"row"},[_c('span',{staticClass:"col-4"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),(_vm.data[key])?_c('span',{staticClass:"col"},[_vm._v(_vm._s(_vm.data[key]))]):_vm._e()])}),0),_c('div',_vm._l((_vm.labelChipMap),function(ref){
              var label = ref.label;
              var desc = ref.desc;
return _c('div',{key:desc,staticClass:"row items-center"},[_c('span',{staticClass:"col-4"},[_vm._v(_vm._s(label))]),_c('span',{staticClass:"q-ml-sm q-mr-sm"},[_vm._v(":")]),_c('q-chip',{attrs:{"dense":"","outline":"","square":"","color":"primary"}},[_vm._v(_vm._s(desc))])],1)}),0)])]),_c('q-tab-panel',{staticClass:"q-pa-md",staticStyle:{"max-height":"60vh","height":"60vh"},attrs:{"name":"contacts"}},[_c('div',{staticClass:"q-pb-md q-gutter-md"},_vm._l((_vm.data.supplierDetails),function(supplierDetail,index){return _c('RegisteredSupplierContactCard',{key:index,attrs:{"data":supplierDetail}})}),1)])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }